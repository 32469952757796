import * as React from "react";
import { styled } from "@mui/system";
import { StaticImage } from "gatsby-plugin-image";

const MyRoot = styled("div")({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
});

/** 工事中ページ */
export default function Preparing() {
    return (
        <MyRoot>
            <Image />
        </MyRoot>
    );
}

/** 工事中画像 */
function Image() {
    return (
        <StaticImage
            src="../images/Preparing.png"
            alt="Preparing"
            placeholder="blurred"
            layout="fixed"
            width={500}
            height={500}
        />
    );
}